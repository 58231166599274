<template>

    <div class="content">
      <h1>Monkey Council Campaign</h1>
      <h3>Overview of Leadership</h3>
      <ul>
        <li>Maintain the current monkey theme of the server</li>
        <li>Restructure role system (with a new naming convention)</li>
        <li>Removing unused channels, and integrating their purpose with other channels e.g. combining monkey events and monkey advertisements</li>
        <li>Fart freedom, no public shame for being a lil gassy</li>
        <li>#basketball-monkeys</li>
        <li>better use of available nitro features, custom invite, server banner and emojis</li>
      </ul>
      <h3>Democracy</h3>
      <ul>
        <li>All medium-large changes to server will require a majority vote</li>
        <li>Vote kick command (will add to yellow bot)</li>
        <li>Monthly surveys on monkey council, with questions like; What do you dislike about the server? (with a lil bit of funny as well)</li>
      </ul>
    </div>
</template>
